body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button:focus {outline:0;}

.toast-success-container {
  color: #fff !important;
  background: #339379 !important;
}

video {
  transform:scaleX(-1);
  object-fit:contain;
  background-color: black;
}
.media-controls .MuiButton-contained.Mui-disabled { 
    box-shadow: none;
    background-color: #ccc !important;
}
